






























































































































































































































import {
    Component,
    Vue
} from 'vue-property-decorator';
 

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'

@Component({
    components: {
    
    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    }
})
export default class Home extends Vue {

    private user: any = {}
    private year: any = []
    private rates: any = []
    private dialog: boolean = false
    private rate: any = {}
    private form: any = {}
    private formUpdate: any = null

    private urlList:any = null
    private urlResponse:boolean = true
    private url:any = []
    private urlForm:any = {}
    


    private rateDatas: any = []
    private response: boolean = false

   

    public async created() {

        await this.run()

    }

    private async run() {
        let loader = await this.$loading.show()
        this.response = false;
        this.user = await User.getUser();
        this.year = await Core.getHttp(`/api/ita/v2/year/${this.$route.query.year}/`)
        this.rates = await Core.getHttp(`/api/ita/v2/rate/${this.$route.query.year}/`)
        for (let i = 0; i < this.rates.length; i++) {
            let result = await this.getResultByUser(i)
            this.rates[i].result = (result.id) ? result : null
        }
        this.response = true;
        await loader.hide()
    }

    public async openDialog(rate: any) {
        this.rate = rate
        this.form.rate = rate.id
        this.form.name = rate.name
        await this.loadRateData();
        this.dialog = true;
    }

    public async closeDialog() {
        this.rate = null
        this.form = {}
        this.dialog = false;
    }

    private async saveData() {
        this.form.user = this.user.pk
        this.form.agency = this.user.ext_link.agency
        let data = await Core.postHttp(`/api/ita/v1/rateresult/`, this.form)
        if (data.id) {
            await this.loadRateData()
            // this.form.rate = rate.id
            // this.form.name = rate.name
            // this.form = {}
        }
        await this.run()
    }

    private async loadRateData() {
        this.rateDatas = await Core.putHttp(`/api/ita/v2/rateresult/`, {
            "agency": this.user.ext_link.agency,
            "rate": this.rate.id
        })
        await this.loadUrlInRate(this.rateDatas.id)
        if (this.rateDatas.id) {
            this.form = this.rateDatas
        }
    }

    private async getResultByUser(i: number) {
        return await Core.putHttp(`/api/ita/v2/rateresult/`, {
            "agency": this.user.ext_link.agency,
            "rate": this.rates[i].id
        })
    }

    private async updateData(data: any) {
        let update = await Core.putHttp(`/api/ita/v1/rateresult/${data.id}/`, data)
        if (update.id) {
            await this.loadRateData()
        }
    }

    private async removeData(data: any) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะลบรายการนี้")
        if (check) {
            let remove = await Core.deleteHttp(`/api/ita/v1/rateresult/${data.id}/`)

        }
        await this.loadRateData()

    }

    private async passingStore(data: any) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะส่งเนื้อหานี้ให้กับผู้ตรวจ")
        if (check) {
            let form = {
                "user_passing": this.user.pk,
                "passing": true
            }
            let store = await Core.putHttp(`/api/ita/v2/passing/${data.id}/`, form)
            if (store.id) {
                await this.loadRateData()
            }
        }

    }

    private async removePassingStore(data: any) {
        let check = confirm("คุณแน่ใจใช่ไหมที่จะส่งเนื้อหานี้ให้กับผู้ตรวจ")
        if (check) {
            let form = {
                "user_passing": this.user.pk,
                "passing": false
            }
            let store = await Core.putHttp(`/api/ita/v2/passing/${data.id}/`, form)
            if (store.id) {
                await this.loadRateData()
            }
        }

    }

    private async loadUrlInRate(rateId:number){
        this.urlList =  await Core.getHttp(`/api/ita/v1/urlrate/?rateresult=${rateId}`)
    }
    private async storeUrlInRate( ){  
        if(!this.rateDatas.id){
         await this.saveData();
        }
        this.urlForm.rateresult = this.rateDatas.id
        await Core.postHttp(`/api/ita/v1/urlrate/`,this.urlForm)  
        this.urlForm = {};
        await this.loadUrlInRate(this.rateDatas.id)
    }
    private async editUrlInRate(url:any){
      await Core.putHttp(`/api/ita/v1/urlrate/${url.id}/`,url)
      await this.loadUrlInRate(this.rateDatas.id)
      alert('แก้ไขลิ้งสำเร็จแล้ว')
    }
    private async removeUrlInRate(rateId:number,id:number){
      await Core.deleteHttp(`/api/ita/v1/urlrate/${id}/`)
      await this.loadUrlInRate(rateId)
    }

    private openLink(url: string) {
        window.open(url, '_blank');
    }

}
